import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const ForumPage: React.FC = () => (
    <Layout>
        <SEO title="Contact Us" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">Forum</h1>
                <p>
                    The forum is offline for maintenance, but check back soon
                    for updates.
                </p>

                {/*
                <p>
                    The forum has moved to{" "}
                    <a href="https://forum.autismsuccessintech.org/">
                        forum.autismsuccessintech.org
                    </a>
                </p>
                */}
                <p>
                    For questions and suggestions, please{" "}
                    <Link to="/contact/">contact us</Link>.
                </p>
            </div>
        </section>
    </Layout>
);

export default ForumPage;
